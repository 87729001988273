import { Button, Stack } from '@mui/material'
import { useCallback, useMemo } from 'react';
import { exportNDownloadFileCsv } from '../../utilities/exportCsv';
import { MRT_Row, MRT_TableInstance } from 'material-react-table';
import { mkConfig } from 'export-to-csv';
import { columnKeys, displayChannelType } from './types';
import { econsentColumnModel } from '../../services/econsent/types';

interface Props {
    table: MRT_TableInstance<{ [key: string]: string; }>;
    isLoading: boolean;
}
const columnHeaderNames = {
    ...columnKeys,
    cancelledBy: "Cancelled by",
    createdAt: "eSignature created Date/Time"
}
const sortedColumn = [
    "appointmentDateTime",
    "remainingDays",
    "hn",
    "fullName",
    "fullNameTh",
    "status",
    "templateName",
    "salesforce_Case_Appointment_Id",
    "email",
    "nationalID",
    "passportNo",
    "birthDate",
    "voidedReason",
    "cancelledBy",
    "createdAt",
    "patientSubmittedAt",
    "witnessSubmittedAt",
    "e_Signature_Link",
    "channel",
    "createdBy",
    "delegateTo",
    "note"
] as (keyof typeof columnHeaderNames)[]

const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    quoteStrings: false,
    columnHeaders: sortedColumn.map(key => ({
        key,
        displayLabel: columnHeaderNames[key]
    }))
});

const ExportAction = ({
    table,
    isLoading
}: Props) => {
    const filteredData = useMemo(() => table.getPrePaginationRowModel().rows, [table])
    const pagingData = useMemo(() => table.getRowModel().rows, [table])

    const handleDownloadCsv = useCallback((datas: MRT_Row<{}>[]) => {
        const rowDatas = datas
            .map(data => data.original as econsentColumnModel)
            .map(data => ({
                ...data,
                channel: displayChannelType[data.channel],
                remainingDays: data.remainingDays < 0 ? 0: data.remainingDays
            }));

        exportNDownloadFileCsv(rowDatas, csvConfig);
    }, [])
    const handleDownloadFilteredData = useCallback(() => {
        handleDownloadCsv(filteredData)
    }, [filteredData])
    const handleDownloadPagingData = useCallback(() => {
        handleDownloadCsv(pagingData)
    }, [pagingData])
    
    return (
        <Stack direction={'row'} spacing={2} mt={2}>
            <Button
                variant='contained'
                onClick={handleDownloadFilteredData}
                disabled={filteredData.length === 0 || isLoading}
            >
                Export filtered data to CSV
            </Button>
            <Button
                variant='contained'
                onClick={handleDownloadPagingData}
                disabled={pagingData.length === 0 || isLoading}
            >
                Export page rows to CSV
            </Button>
        </Stack>
    )
}

export default ExportAction