import { useState } from 'react';
import { Box, TextField } from '@mui/material';
import { updateNoteByTransaction } from '../../services/econsent/econsentServices'

interface Props {
    note: string;
    transactionId: string;
}

const ConsentNote = ({
    note,
    transactionId
}:Props) => {
    const [noteText, setNoteText] = useState<string>(note);

    const handleUpdate = (e: any) => {
        setNoteText(e.target.value);
    }

    const handleBlur = (e: any) => {
        updateNoteByTransaction({ transactionId, note: noteText });
    }

return (  
    <Box
        sx={{
          display: 'inline-block',
          alignItems: 'center',
          gap: '1rem',
        }}
      >
        <TextField value={noteText} hiddenLabel multiline variant="outlined" onChange={handleUpdate} onBlur={handleBlur} />
      </Box>
);
}

export default ConsentNote;