import { statusType, econsentDetailsModel, channelType, CancellationReasonMaster, companyType } from './types';
import econsentAPI from './econsentApi';
import { UUID_REGEX_PATTERN } from '../onetrust/types';
import { User } from '../signin';
import DateFnsUtils from "@date-io/date-fns";

const dateFns = new DateFnsUtils()

interface typeParams {
  type: statusType;
  channel: channelType;
  company: companyType;
  isDelegateToMe: boolean;
  sync?: Date;
}

const EconsentDetails = async ({ type, channel, company, isDelegateToMe, sync }: typeParams): Promise<econsentDetailsModel[]> => {
  if (type === statusType.UNDEFINED) return Promise.resolve([]);
  const res = await econsentAPI.get<econsentDetailsModel[]>(
    `${process.env.REACT_APP_ECONSENT_SUFFIX}/econsent/details?type=${type}&channel=${channel}&company=${company}&isDelegateToMe=${isDelegateToMe}&sync=${sync ? dateFns.format(sync, "yyyy-MM-dd"): ""}`
  )
    .then((res) => res.data)

  return res;
}

export const fetchData = async (type: statusType, channel: channelType, company: companyType, isDelegateToMe: boolean, sync?: Date) => {
  return await EconsentDetails({ type: type, channel, company, isDelegateToMe, sync})
    .then((res) => {
      res = [...res].sort((a, b) => {
        const date1: number = Number(new Date(a.appointmentDateTime ?? 0));
        const date2: number = Number(new Date(b.appointmentDateTime ?? 0));
        return date2 - date1;
      });

      return mapToColumnModel(res)
    });
}

export const fetchDataPending = async (channel: channelType, company: companyType, isDelegateToMe: boolean, sync?: Date,) => {
  const pendingCreated = await EconsentDetails({ type: statusType.PENDING_TO_CREATE_TRANSACTION, channel,company, isDelegateToMe,sync });
  const pendingPatient = await EconsentDetails({ type: statusType.PENDING_PATIENT, channel, company, isDelegateToMe, sync });
  let all = pendingCreated.concat(pendingPatient);
  all = [...all].sort((a, b) => {
    const date1: number = Number(new Date(a.appointmentDateTime ?? 0));
    const date2: number = Number(new Date(b.appointmentDateTime ?? 0));
    return date2 - date1;
  });
  return mapToColumnModel(all)
}

export const fetchDataAll = async (channel: channelType, company: companyType, isDelegateToMe: boolean, sync?: Date) => {
  const pendingCreated = await EconsentDetails({ type: statusType.PENDING_TO_CREATE_TRANSACTION, channel, company, isDelegateToMe, sync });
  const pendingPatient = await EconsentDetails({ type: statusType.PENDING_PATIENT, channel, company, isDelegateToMe, sync });
  const pendingWitness = await EconsentDetails({ type: statusType.PENDING_WITNESS, channel, company, isDelegateToMe, sync });
  const completed = await EconsentDetails({ type: statusType.COMPLETED, channel,company, isDelegateToMe, sync });
  const cancelled = await EconsentDetails({ type: statusType.CANCELLED, channel,company, isDelegateToMe, sync });
  const declined = await EconsentDetails({ type: statusType.DECLINED, channel, company, isDelegateToMe, sync });
  const expired = await EconsentDetails({ type: statusType.EXPIRED, channel,company, isDelegateToMe, sync });
  let all = pendingCreated.concat(pendingPatient, pendingWitness, completed, cancelled, declined, expired);
  all = [...all].sort((a, b) => {
    const date1: number = Number(new Date(a.appointmentDateTime ?? 0));
    const date2: number = Number(new Date(b.appointmentDateTime ?? 0));
    return date2 - date1;
  });
  return mapToColumnModel(all)
}

export const fetchDataCancelled = async (channel: channelType, company: companyType, isDelegateToMe: boolean, sync?: Date) => {
  const cancelled = await EconsentDetails({ type: statusType.CANCELLED, channel,company, isDelegateToMe, sync });
  const declined = await EconsentDetails({ type: statusType.DECLINED, channel, company, isDelegateToMe, sync });
  const expired = await EconsentDetails({ type: statusType.EXPIRED, channel, company, isDelegateToMe, sync });
  let all = cancelled.concat(declined, expired);
  all = [...all].sort((a, b) => {
    const date1: number = Number(new Date(a.appointmentDateTime ?? 0));
    const date2: number = Number(new Date(b.appointmentDateTime ?? 0));
    return date2 - date1;
  });
  return mapToColumnModel(all)
}

function mapToColumnModel(data: econsentDetailsModel[]): Record<string, string>[] {
  return data.map((item) => {
    return {
      appointmentDateTime: item.appointmentDateTime ?? "",
      remainingDays: calculatedAppointmentDateDiff(item.appointmentDateTime),
      hn: item.hn ?? "",
      fullName: item.fullName ?? "",
      fullNameEn: item.fullNameEn || (item.fullNameTh ? null : item.fullName) || "",
      fullNameTh: item.fullNameTh ?? "",
      status: item.status ?? "",
      templateName: item.templateName ?? "",
      transactionId: item.transactionId ?? "",
      salesforce_Case_Appointment_Id: item.salesforce_Case_Appointment_Id ?? "",
      email: item.email ?? "",
      nationalID: item.nationalID ?? "",
      passportNo: item.passportNo ?? "",
      birthDate: item.birthDate ?? "",
      voidedReason: item.voidedReason ?? "",
      patientSubmittedAt: item.patientSubmittedAt ?? "",
      witnessSubmittedAt: item.witnessSubmittedAt ?? "",
      e_Signature_Link: item.e_Signature_Link ?? "",
      in_person_signing: "",
      cancelledBy: item.cancelledBy ?? "",
      channel: mapToChannelType(item.channel),
      company: mapToCompanyType(item.company),
      createdAt: item.createdAt ? `${item.createdAt}Z`: "",
      createdBy: item.createdBy ?? "",
      delegateTo: item.delegateTo ?? "",
      note: item.note ?? ""
    };
  });
}

function mapToChannelType(channel: string | null): channelType {
  switch (channel) {
    case channelType.SALEFORCE: return channelType.SALEFORCE
    case channelType.IPS: return channelType.IPS
    case channelType.TRAKCARE: return channelType.TRAKCARE
    default: return channelType.NONE
  }
}

function mapToCompanyType(company: string | null): companyType {
  switch (company) {
    case companyType.BUMRUNGRAD: return companyType.BUMRUNGRAD
    case companyType.VITALLIFE: return companyType.VITALLIFE
    case companyType.ESPERANCE: return companyType.ESPERANCE
    default: return companyType.NONE
  }
}

function calculatedAppointmentDateDiff(appointmentDate: string | null): string {
  if (appointmentDate === null) return '-999';
  const date1: Date = new Date(appointmentDate);
  const date2: Date = new Date();
  const diffTime = date1.getTime() - date2.getTime();
  const diffDays = Math.trunc(diffTime / (1000 * 60 * 60 * 24));
  return String(diffDays);
}

interface transactionIdtypeParams {
  transactionId: string;
}

export const downloadPdf = async ({ transactionId }: transactionIdtypeParams): Promise<Blob> => {
  if (!isUUID(transactionId)) return Promise.reject("transactionId is not UUID");
  const res = await econsentAPI.get<Blob>(
    `${process.env.REACT_APP_ECONSENT_SUFFIX}/econsent/file?transactionId=${transactionId}`, { responseType: 'arraybuffer' }
  )
    .then((res) => new Blob([res.data], { type: 'application/pdf' }))

  return res;
}

const isUUID = (uuid: string): Boolean => {
  // let regRef = new RegExp('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$');
  return UUID_REGEX_PATTERN.test(uuid);
}

export const cancelPendingTransaction = async ({ transactionId, reason }: transactionIdtypeParams & { reason: string }): Promise<boolean> => {
  if (!isUUID(transactionId)) return Promise.reject("transactionId is not UUID");

  const user = JSON.parse(sessionStorage.getItem('user') ?? "{}") as User;
  let data = JSON.stringify({
    reason,
    cancelBy: `${user.id} - ${user.name}`
  });
  const res = await econsentAPI
    .delete<boolean>(
      `${process.env.REACT_APP_ECONSENT_SUFFIX}/econsent/transaction/${transactionId}`,
      {
        headers: {
          'Content-Type': 'application/json', 
          'Accept': 'text/plain'
        },
        data
      }
    )
    .then((res) => res.data)

  return res;
}

export const updateNoteByTransaction = async ({ transactionId, note }: transactionIdtypeParams & { note: string }): Promise<boolean> => {
  if (!isUUID(transactionId)) return Promise.reject("transactionId is not UUID");

  const user = JSON.parse(sessionStorage.getItem('user') ?? "{}") as User;
  try
  {
    const res = await econsentAPI.patch<boolean>(
      `${process.env.REACT_APP_ECONSENT_SUFFIX}/econsent/transaction/note/${transactionId}`,
      {
        note,
        notedBy: `${user.name}`
      },
      {
        headers: {
          'Content-Type': 'application/json', 
          'Accept': 'text/plain'
        }
      }
    )
    .then((res) => res.data)
    return res;
  }
  catch (e)
  {
    console.log(e);
    return false;
  }
}

export const getCancellationReasonsMaster = async (): Promise<CancellationReasonMaster[]> => {
  const res = await econsentAPI
    .get<CancellationReasonMaster[]>(
      `${process.env.REACT_APP_ECONSENT_SUFFIX}/master/reason/cancel`
    )
    .then((res) => res.data);
  
    return res;
}