import React, { useCallback, useState } from 'react';
import { MaterialReactTable, MaterialReactTableProps, MRT_VisibilityState, type MRT_Row } from 'material-react-table';
import { OnChangeFn } from '@tanstack/react-table'
import { Stack } from '@mui/material';

import { channelType, companyType } from '../../services/econsent/types';
import { columnKeys } from './types';
import { useColumnConfig } from './column.hook';
import PdfModal from '../../components/ModalPdf/PdfModal';
import ConfirmCancelModal from '../../components/Modal/ConfirmCancelModal';

import ToolbarAction from './toolbar.action';

const DashBoard = () => {
  const [channel, setChannel] = useState<channelType>(channelType.NONE);
  const [company, setCompany] = useState<companyType>(companyType.NONE);
  const [isDelegateToMe, setDelegateToMe] = useState<boolean>(false);
  const [metadata, setMetaData] = React.useState<{ [key: string]: string }[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const renderRowActions = useCallback(({ row }: Parameters<NonNullable<MaterialReactTableProps<typeof columnKeys>["renderRowActions"]>>[0]) => {
    return (
      <Stack direction={'row'}>
        {PdfModal(row as MRT_Row)}
        {ConfirmCancelModal(row as MRT_Row)}
      </Stack>
    )
  }, [])

  const [columns, columnOrder, [columnVisibility, setColumnVisibility]] = useColumnConfig(channel);

  return (
    <MaterialReactTable
      columns={columns}
      data={metadata}
      state={{
        isLoading: isLoading,
        columnOrder: columnOrder,
        columnVisibility: columnVisibility
      }}
      onColumnVisibilityChange={setColumnVisibility as OnChangeFn<MRT_VisibilityState>}
      initialState={{
        showColumnFilters: true,
        showGlobalFilter: true,
        density: 'compact',
        pagination: { pageIndex: 0, pageSize: 20 },
      }}
      globalFilterFn={'contains'}
      // enableGlobalFilterRankedResults={false}
      enableRowActions
      renderRowActions={renderRowActions}
      muiTableBodyProps={{
        sx: {
          //stripe the rows, make odd rows a darker color
          '& tr:nth-of-type(odd)': {
            backgroundColor: '#f5f5ff',
          },
        },
      }}
      muiTableBodyCellProps={{
        sx: {
          borderRight: '2px solid #e0e0e0', //add a border between columns
          textAlign: 'center',
        },
      }}
      renderTopToolbarCustomActions={({ table }) => <ToolbarAction
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        channel={channel}
        company={company}
        isDelegateToMe={isDelegateToMe}
        setMetaData={setMetaData}
        setChannel={setChannel}
        setCompany={setCompany}
        setDelegateToMe={setDelegateToMe}
        table={table}
      />}
    />
  );
}

export default DashBoard;