import { Stack, Switch, FormControlLabel } from '@mui/material'



type Props = {
    onToggleDelegateToMe: (isDelegateToMe: boolean) => void;
    isDelegateToMe: boolean;
}

const DelegateToMeFilter = ({
    onToggleDelegateToMe,
    isDelegateToMe
}: Props) => {

    return (
        <Stack direction={'row'} spacing={1} mb={1}>
            <FormControlLabel
                control={
                    <Switch
                    checked={isDelegateToMe}
                    onChange={() => onToggleDelegateToMe(!isDelegateToMe)}
                    color="primary"
                    />
                }
                label={isDelegateToMe ? 'Delegate to me' : 'Delegate to all'}
            />
        </Stack>
    )
}

export default DelegateToMeFilter