import { Button, ButtonProps, Stack } from '@mui/material'
import { companyType as companyTypeDtos } from '../../services/econsent/types';
import { CompanyType, mapToCompanyTypeDtos } from './types'

const colors = {
    [CompanyType.BUMRUNGRAD]: 'success',
    [CompanyType.VITALLIFE]: 'warning',
    [CompanyType.ESPERANCE]: 'secondary',
} as Record<CompanyType, ButtonProps['color']>

type Props = {
    isLoading: boolean;
    onToggleCompany: (CompanyType: companyTypeDtos) => void;
    company: companyTypeDtos
}

const CompanyFilter = ({
    isLoading,
    onToggleCompany,
    company
}: Props) => {
    return (
        <Stack direction={'row'} spacing={1} mb={1}>
            {
                Object.entries(CompanyType)
                    .filter(([_, label]) => label != CompanyType.NONE && label != CompanyType.ALL)
                    .map(([key, label]) => {
                        const dtos = mapToCompanyTypeDtos(label)

                        return (
                            <Button
                                key={key}
                                color={colors[label]}

                                onClick={() => onToggleCompany(dtos)}
                                sx={{ flex: 1 }}
                                variant={company === dtos ? "outlined" : "contained"}
                                disabled={isLoading}
                            >
                                {label}
                            </Button>
                        )
                    })
            }
        </Stack>
    )
}

export default CompanyFilter