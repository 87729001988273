import { useEffect, useMemo, useState } from 'react';
import { type MRT_ColumnDef, MRT_VisibilityState } from 'material-react-table';
import { Box, Tooltip } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';

import { columnPropertyName, columnKeys, statusType as statusFilter, statusType } from './types';
import CustomizeButton from '../../components/Button/CustomizeButton';
import { CustomDatePicker } from '../../components/DatePicker/CustomDatePicker';
import { channelType } from '../../services/econsent/types';
import ConsentNote from './consentNote';


function datetimeToFormatString(dateTime: Date | undefined, options: Intl.DateTimeFormatOptions) {
  if (isNaN(Number(dateTime)) || dateTime === undefined)
    dateTime = new Date("2001-01-01T00:00:00");
  return new Date(dateTime).toLocaleDateString("en-GB", options);
}

const defaultColumns  = [
  {
    accessorFn: (row) => Date.parse(row[columnPropertyName.appointmentDateTime]) || Date.parse("1001-01-01T00:00:00"), //convert to Date for sorting and filtering
    id: columnPropertyName.appointmentDateTime,
    header: columnKeys[columnPropertyName.appointmentDateTime],
    size: 50,
    filterFn: 'greaterThanOrEqualTo',
    // filterVariant: 'text',
    sortingFn: 'datetime',
    Cell: ({ cell }) => (
      <Box
        component="span"
        sx={(theme) => ({
          borderRadius: '0.25rem',
          color:
            cell.getValue<Date>() <= new Date("1001-01-01T00:00:00")
              ? "transparent"
              : "#000",
          maxWidth: '9ch',
          p: '0.25rem',
        })}
      >
        {datetimeToFormatString(cell.getValue<Date>(), { timeZone: 'Asia/Bangkok', year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false })}
      </Box>
    ),
    // Header: ({ column }) => <em>{column.columnDef.header}</em>, //custom header markup
    Filter: ({ column }) => <CustomDatePicker {...column} />,
  },
  {
    accessorFn: (row) => Number(row[columnPropertyName.remainingDays]),
    id: columnPropertyName.remainingDays,
    header: columnKeys[columnPropertyName.remainingDays],
    filterVariant: 'text', // default
    size: 50,
    // sortingFn: (rowA: any, rowB: any, columnId: any): number =>
    // rowA.getValue(columnId).value === 'x' ? 0 : (rowA.getValue(columnId).value < rowB.getValue(columnId).value ? 1 : -1),
    Cell: ({ cell }) => {
      const value = cell.getValue<number>();
      return (
        <Box
          component="span"
          sx={(theme) => ({
            backgroundColor:
              value < 0
                ? theme.palette.error.dark
                : value < 6
                  ? theme.palette.warning.dark
                  : theme.palette.success.dark,
            borderRadius: '0.25rem',
            color: '#fff',
            maxWidth: '9ch',
            p: '0.25rem',
          })}
        >
          {value < 0 ? 0 : value}
        </Box>
      )
    }
  },
  {
    accessorFn: (row) => row[columnPropertyName.hn],
    id: columnPropertyName.hn,
    header: columnKeys[columnPropertyName.hn],
    filterVariant: 'text', // default
    filterFn: 'includesString',
    size: 50,
  },
  {
    accessorFn: (row) => row[columnPropertyName.fullNameEn],
    id: columnPropertyName.fullName,
    header: columnKeys[columnPropertyName.fullName],
    filterVariant: 'text', // default
    filterFn: 'includesString',
    size: 50,
  },
  {
    accessorFn: (row) => row[columnPropertyName.fullNameTh],
    id: columnPropertyName.fullNameTh,
    header: columnKeys[columnPropertyName.fullNameTh],
    filterVariant: 'text', // default
    filterFn: 'includesString',
    size: 50,
  },
  {
    accessorFn: (row) => statusFilter[row[columnPropertyName.status]],
    id: columnPropertyName.status,
    header: columnKeys[columnPropertyName.status],
    filterVariant: 'select',
    filterSelectOptions: Object.keys(statusFilter).map((key) => statusFilter[key]),
    size: 50,
    Cell: ({ cell: { row: { original: row } } }) => {
      const title = row.status === statusType.CANCELLED ? `Cancelled by: ${row.cancelledBy}` : ''

      return (
        <Tooltip arrow placement="bottom" title={title}>
          <div>
            {statusFilter[row[columnPropertyName.status]]}
          </div>
        </Tooltip>
      )
    },
  },
  {
    accessorFn: (row) => row[columnPropertyName.templateName],
    id: columnPropertyName.templateName,
    header: columnKeys[columnPropertyName.templateName],
    filterVariant: 'text', // default
    filterFn: 'includesString',
    size: 50,
  },
  {
    accessorFn: (row) => row[columnPropertyName.salesforce_Case_Appointment_Id],
    id: columnPropertyName.salesforce_Case_Appointment_Id,
    header: columnKeys[columnPropertyName.salesforce_Case_Appointment_Id],
    filterVariant: 'text', // default
    filterFn: 'includesString',
    size: 50,
    enableClickToCopy: true
  },
  {
    accessorFn: (row) => row[columnPropertyName.email],
    id: columnPropertyName.email,
    header: columnKeys[columnPropertyName.email],
    filterVariant: 'text', // default
    filterFn: 'includesString',
    size: 50,
  },
  {
    accessorFn: (row) => row[columnPropertyName.nationalID],
    id: columnPropertyName.nationalID,
    header: columnKeys[columnPropertyName.nationalID],
    filterVariant: 'text', // default
    filterFn: 'includesString',
    size: 50,
  },
  {
    accessorFn: (row) => row[columnPropertyName.passportNo],
    id: columnPropertyName.passportNo,
    header: columnKeys[columnPropertyName.passportNo],
    filterVariant: 'text', // default
    filterFn: 'includesString',
    size: 50,
  },
  {
    accessorFn: (row) => Date.parse(row[columnPropertyName.birthDate]) || Date.parse("1001-01-01T00:00:00"), //convert to Date for sorting and filtering
    id: columnPropertyName.birthDate,
    header: columnKeys[columnPropertyName.birthDate],
    filterFn: 'greaterThanOrEqualTo',
    sortingFn: 'datetime',
    size: 50,
    // Cell: ({ cell }) => datetimeToFormatString(cell.getValue<Date>(), { timeZone: 'Asia/Bangkok', year: 'numeric', month: 'short', day: 'numeric'}),
    Cell: ({ cell }) => (
      <Box
        component="span"
        sx={(theme) => ({
          borderRadius: '0.25rem',
          color:
            cell.getValue<Date>() <= new Date("1001-01-01T00:00:00")
              ? "transparent"
              : "#000",
          maxWidth: '9ch',
          p: '0.25rem',
        })}
      >
        {datetimeToFormatString(cell.getValue<Date>(), { timeZone: 'Asia/Bangkok', year: 'numeric', month: 'short', day: 'numeric' })}
      </Box>
    ),
    Filter: ({ column }) => <CustomDatePicker {...column} />,
  },
  {
    accessorFn: (row) => row[columnPropertyName.voidedReason],
    id: columnPropertyName.voidedReason,
    header: columnKeys[columnPropertyName.voidedReason],
    filterVariant: 'text', // default
    size: 50,
  },
  {
    accessorFn: (row) => Date.parse(row[columnPropertyName.patientSubmittedAt]) || Date.parse("1001-01-01T00:00:00"), //convert to Date for sorting and filtering
    id: columnPropertyName.patientSubmittedAt,
    header: columnKeys[columnPropertyName.patientSubmittedAt],
    filterFn: 'greaterThanOrEqualTo',
    sortingFn: 'datetime',
    size: 50,
    Cell: ({ cell }) => (
      <Box
        component="span"
        sx={(theme) => ({
          borderRadius: '0.25rem',
          color:
            cell.getValue<Date>() <= new Date("1001-01-01T00:00:00")
              ? "transparent"
              : "#000",
          maxWidth: '9ch',
          p: '0.25rem',
        })}
      >
        {datetimeToFormatString(cell.getValue<Date>(), { timeZone: 'Asia/Bangkok', year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false })}
      </Box>
    ),
    Filter: ({ column }) => <CustomDatePicker {...column} />,
  },
  {
    accessorFn: (row) => Date.parse(row[columnPropertyName.witnessSubmittedAt]) || Date.parse("1001-01-01T00:00:00"), //convert to Date for sorting and filtering
    id: columnPropertyName.witnessSubmittedAt,
    header: columnKeys[columnPropertyName.witnessSubmittedAt],
    filterFn: 'greaterThanOrEqualTo',
    sortingFn: 'datetime',
    size: 150,
    Cell: ({ cell }) => (
      <Box
        component="span"
        sx={(theme) => ({
          borderRadius: '0.25rem',
          color:
            cell.getValue<Date>() <= new Date("1001-01-01T00:00:00")
              ? "transparent"
              : "#000",
          maxWidth: '9ch',
          p: '0.25rem',
        })}
      >
        {datetimeToFormatString(cell.getValue<Date>(), { timeZone: 'Asia/Bangkok', year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false })}
      </Box>
    ),
    Filter: ({ column }) => <CustomDatePicker {...column} />,
  },
  {
    accessorFn: (row) => row[columnPropertyName.e_Signature_Link],
    id: columnPropertyName.e_Signature_LinkText,
    header: columnKeys[columnPropertyName.e_Signature_Link],
    enableGlobalFilter: false,
    enableColumnFilter: false,
    enableSorting: false,
    enableClickToCopy: true,
    Cell: ({ cell, row }) => {
      const value = cell.getValue<string>()
      const wasShortened = value?.startsWith('https://bhx.one/')

      if (wasShortened) {
        return value
      }

      return (
        <span
          style={{
            display: 'inline-block',
            maxWidth: '200px',
            textOverflow: 'ellipsis',
            overflow: 'hidden'
          }}
        >
          {value}
        </span>
      )
    }
  },
  {
    accessorFn: (row) => row[columnPropertyName.e_Signature_Link],
    id: columnPropertyName.e_Signature_Link,
    header: columnKeys[columnPropertyName.e_Signature_Link],
    size: 100,
    enableGlobalFilter: false,
    enableColumnFilter: false,
    enableSorting: false,
    Cell: ({ cell, row }) => (
      <Box
        sx={{
          display: 'inline-block',
          alignItems: 'center',
          gap: '1rem',
        }}
      >
        {
          cell.getValue<string>() !== ''
            ? <CustomizeButton
              variant="contained"
              href={cell.getValue<string>()}
              target="_blank"
              endIcon={<SendIcon />}
              disabled={[statusType.COMPLETED, statusType.CANCELLED, statusType.DECLINED, statusType.EXPIRED].includes(row.original.status)}
            >
              CLICK
            </CustomizeButton>
            : "-"
        }
      </Box>
    )
  },
  {
    accessorFn: (row) => row[columnPropertyName.channel],
    id: columnPropertyName.channel,
    header: columnKeys[columnPropertyName.channel],
    filterVariant: 'select',
    filterSelectOptions: Object.values(channelType).filter(value => value != channelType.ALL).map((value) => value),
    size: 25,
  },
  {
    accessorFn: (row) => Date.parse(row[columnPropertyName.createdAt]) || Date.parse("1001-01-01T00:00:00"), //convert to Date for sorting and filtering
    id: columnPropertyName.createdAt,
    header: columnKeys[columnPropertyName.createdAt],
    filterFn: 'greaterThanOrEqualTo',
    sortingFn: 'datetime',
    size: 50,
    Cell: ({ cell }) => (
      <Box
        component="span"
        sx={(theme) => ({
          borderRadius: '0.25rem',
          color:
            cell.getValue<Date>() <= new Date("1001-01-01T00:00:00")
              ? "transparent"
              : "#000",
          maxWidth: '9ch',
          p: '0.25rem',
        })}
      >
        {datetimeToFormatString(cell.getValue<Date>(), { timeZone: 'Asia/Bangkok', year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false })}
      </Box>
    ),
    Filter: ({ column }) => <CustomDatePicker {...column} />,
  },
  {
    accessorFn: (row) => row[columnPropertyName.transactionId],
    id: columnPropertyName.transactionId,
    header: columnKeys[columnPropertyName.transactionId],
    filterVariant: 'text', // default
    filterFn: 'includesString',
    size: 50,
  },
  {
    accessorFn: (row) => row[columnPropertyName.createdBy],
    id: columnPropertyName.createdBy,
    header: columnKeys[columnPropertyName.createdBy],
    filterVariant: 'text', // default
    filterFn: 'includesString',
    size: 50,
  },
  {
    accessorFn: (row) => row[columnPropertyName.delegateTo],  
    id: columnPropertyName.delegateTo,
    header: columnKeys[columnPropertyName.delegateTo],
    filterVariant: 'text', // default
    filterFn: 'includesString',
    size: 50,
  },
  {
    accessorFn: (row) => row[columnPropertyName.note],
    id: columnPropertyName.note,
    header: columnKeys[columnPropertyName.note],
    size: 100,
    enableGlobalFilter: false,
    enableColumnFilter: false,
    enableSorting: false,
    Cell: ({ row }) => (
      <ConsentNote
        note={row.getValue(columnPropertyName.note)}
        transactionId={row.getValue(columnPropertyName.transactionId)}>
      </ConsentNote>
    )
  }
] as MRT_ColumnDef<typeof columnKeys>[]
const defaultColumnIds = ['mrt-row-actions']
const columnIds = defaultColumnIds.concat(defaultColumns.map(it => it.id ?? ''))
const defaultColumnOrder = [
  ...defaultColumnIds,
  columnPropertyName.e_Signature_Link,
  columnPropertyName.e_Signature_LinkText,
  columnPropertyName.patientSubmittedAt,
  columnPropertyName.hn,
  columnPropertyName.fullName,
  columnPropertyName.fullNameTh,
  columnPropertyName.birthDate,
  columnPropertyName.status,
  columnPropertyName.nationalID,
  columnPropertyName.passportNo,
  columnPropertyName.channel,
  columnPropertyName.createdAt,
  columnPropertyName.createdBy,
  columnPropertyName.delegateTo,
  columnPropertyName.note,
]
export const columnOrder = Object.freeze({
  [channelType.ALL]: defaultColumnOrder,
  [channelType.NONE]: defaultColumnOrder,
  [channelType.SALEFORCE]: defaultColumnOrder,
  [channelType.TRAKCARE]: defaultColumnOrder,
  [channelType.IPS]: defaultColumnOrder
})

const allColumnVisibility = {
  ...Object.fromEntries(columnIds.map(id => [id, true])),
  transactionId: false
}
const defaultColumnVisibility = Object.fromEntries(columnIds.map(id => [id, defaultColumnOrder.includes(id ?? '')]))
export const columnVisibility = Object.freeze({
  [channelType.ALL]: defaultColumnVisibility,
  [channelType.NONE]: defaultColumnVisibility,
  [channelType.SALEFORCE]: defaultColumnVisibility,
  [channelType.TRAKCARE]: defaultColumnVisibility,
  [channelType.IPS]: defaultColumnVisibility,
})

export const useColumnConfig = (channel: channelType) => {
  const _columns = useMemo<MRT_ColumnDef<typeof columnKeys>[]>(
    () => defaultColumns,
    [],
  );
  const _columnOrder = useMemo(() => columnOrder[channel] ?? [], [channel])
  const columnVisibilityState = useState<MRT_VisibilityState>(columnVisibility[channel] ?? {})

  useEffect(() => {
    columnVisibilityState[1](columnVisibility[channel] ?? {})
  }, [channel])

  return [_columns, _columnOrder, columnVisibilityState] as [MRT_ColumnDef<typeof columnKeys>[], string[], ReturnType<typeof useState<MRT_VisibilityState>>];
}
